<template>
  <div class="w-full py-16 flex flex-col items-center gap-4">
    <h1 class="text-6xl font-extrabold">
      404
    </h1>
    <p class="text-2xl">
      找不到此页面
    </p>
    <router-link
      to="/"
      class="text-brand hover:underline"
    >
      返回首页
    </router-link>
  </div>
</template>
