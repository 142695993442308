<template>
  <div class="flex justify-center py-8">
    <div class="bg-white dark:bg-neutral-900 p-4 rounded-lg">
      <van-loading
        size="24px"
        vertical
      >
        加载中...
      </van-loading>
    </div>
  </div>
</template>
